import React from "react";
import "./styles.css";
import Featured from "./featured";
import Windows from "./windows";

/**
 *
 * @param {[]}
 */
const Princesses = ({ characters, gameType }) => {
   const [state, setState] = React.useState({ currFeatured: "" });
   return (
      <div className="princesses">
         <Featured
            characters={characters}
            setCurrFeatured={(currFeatured) => setState({ currFeatured })}
         />
			<Windows characters={characters} currFeatured={state.currFeatured} gameType={gameType} />
      </div>
   );
};

export default Princesses;
