import React from 'react';
import './styles.css'
import StateButton from '../../components/state-button';
import { Container, Row, Button, Col } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import Previous from '../../assets/buttons/Previous.svg';
import PreviousNights from "../../assets/buttons/nights/Previous.svg";
import Next from '../../assets/buttons/Nextdeck.svg'
import NextNights from '../../assets/buttons/nights/Nextdeck.svg';
import GameManager from '../../services/game-manager';

const ActionButtons = ({ gameState, action, animation, onAnimateEnd, disabled, onPrevious, onNewDeck, gameType }) => {
	return (
      <Container
         fluid
         className="action-buttons d-flex flex-col justify-content-center align-items-center"
      >
         <Row>
            <Col>
               <IconButton
                  disabled={disabled}
                  onClick={onPrevious}
                  style={
                     gameState === GameManager.GAME_STATES.PICKING
                        ? { display: "none" }
                        : {}
                  }
               >
                  <img
                     src={gameType === "STANDARD" ? Previous : PreviousNights}
                     alt="Previous"
                     style={{ width: "4em", height: "auto" }}
                  />
               </IconButton>
            </Col>
            <Col>
               <StateButton
                  gameState={gameState}
                  onSelect={action}
                  animate={animation}
                  onAnimateEnd={onAnimateEnd}
                  disabled={disabled}
                  gameType={gameType}
               />
            </Col>
            <Col>
               <IconButton
                  disabled={disabled}
                  style={
                     gameState === GameManager.GAME_STATES.PICKING
                        ? { display: "none" }
                        : {}
                  }
                  onClick={onNewDeck}
               >
                  <img
                     src={gameType === "STANDARD" ? Next : NextNights}
                     alt="Next"
                     style={{ width: "4em", height: "auto" }}
                  />
               </IconButton>
            </Col>
         </Row>
      </Container>
   );
}

export default ActionButtons;
