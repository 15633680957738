import CardColor from "./card-color";

export default class SpecialCard {
	word = '';
	color = new CardColor();
	id='';

	/**
	 * 
	 * @param {{word: string, color: {name: string, color: [{name: string, value: string}]}}} card 
	 */
	constructor(card) {
		this.id = card.id;
		this.word = card.word;
		this.color = CardColor._cachedColors[card.color.name];
	}
	
	getColorVal() {
		return this.color.getValue();
	}

	getColorName() {
		return this.color.getName();
	}
}