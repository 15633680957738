import CardSymbol from "./card-symbol";
import SpecialCard from "./special-card";

export default class SpellCard extends SpecialCard {
	word = '';
	symbol = new CardSymbol();
	constructor(card) {
		super(card);
		this.word = card.word;
		this.symbol = CardSymbol._cachedSymbols[card.symbol.name];
	}

	getSymbolName() {
		return this.symbol.name;
	}
}