let apiURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL.trim() : "";


const GET = async (endpoint) => {
	const response = await fetch(apiURL+endpoint, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	});
	return response.json();
};

// const POST = async (endpoint, data = {}) => {
// 	const response = await fetch(apiURL + endpoint, {
// 		method: 'POST',
// 		headers: {
// 			'Content-Type': 'application/json'
// 		},
// 		body: JSON.stringify(data),
// 	});
// 	return response.json();
// };

// const PUT = async (endpoint, data = {}) => {
// 	const response = await fetch(apiURL + endpoint, {
// 		method: 'PUT',
// 		headers: {
// 			'Content-Type': 'application/json'
// 		},
// 		body: JSON.stringify(data),
// 	});

// 	return response.json();
// }

// const DELETE = async (endpoint, data = {}) => {
// 	const response = await fetch(apiURL + endpoint, {
// 		method: 'DELETE',
// 		headers: {
// 			'Content-Type': 'application/json'
// 		},
// 		body: JSON.stringify(data),
// 	});
// 	return response.json();
// }

function buildQuery(query) {
	let queryString = '?_limit=-1&';
	for (const [queryKey, queryVals] of Object.entries(query)) {
		for (const query of queryVals) {
			queryString += queryKey + "=" + query + "&";
		}
	}
	queryString = queryString.slice(0, queryString.length - 1);
	return queryString;
}

/**
 * Get basic cards from the database. If queries is omitted, all basic cards
 * are returned.
 * @param {{[key: string]: Array<string>}} filterQueries - An object of arrays, where the key's are name of the property and the values are an array of property values
 */
export const getSpellCards = (filterQueries={}) => {
	const queryString = buildQuery(filterQueries);
	console.log("/basic-cards" + queryString);
	return GET('/basic-cards'+queryString);
}

/**
 * 
 * @param {{[key: string]: Array<string>}} filterQueries - An object of arrays, where the key's are name of the property and the values are an array of property values
 */
export const getSpecialCards = (filterQueries={}) => {
	const queryString = buildQuery(filterQueries);
	return GET('/neutral-cards'+queryString);
}

/**
 * 
 * @param {{[key: string]: Array<string>}} filterQueries - An object of arrays, where the key's are name of the property and the values are an array of property values
 */
export const getSymbols = async (filterQueries={}) => {
	const queryString = buildQuery(filterQueries);
	return GET('/card-symbols'+queryString);
}

// export const getSymbolImg = async (endpoint) => {
// 	const res = await fetch(endpoint, {
// 		mode: 'no-cors',
// 		headers: {
// 			'Access-Control-Allow-Origin':'*',
// 			'Content-Type': 'application/octet-stream'
// 		}
// 	});
// 	return res.blob();
// }

/**
 * 
 * @param {{[key: string]: Array<string>}} filterQueries - An object of arrays, where the key's are name of the property and the values are an array of property values
 */
export const getColors = async (filterQueries={}) => {
	const queryString = buildQuery(filterQueries);
	return GET('/card-colors'+queryString);
}

/**
 * 
 * @param {{[key: string]: Array<string>}} filterQueries - An object of arrays, where the key's are name of the property and the values are an array of property values
 * @returns {{rules: string}}}
 */
export const getRules = async () => {
	return GET('/game-rules');
}

/**
 * 
 * @param {{[key: string]: Array<string>}} filterQueries - An object of arrays, where the key's are name of the property and the values are an array of property values
 * @returns {Array<{type: string, images: Array<{name: string, url: string}>}}
 */
export const getAds = async (filterQueries={}) => {
	const queryString = buildQuery(filterQueries);
	return GET('/prmtns'+queryString);
}

/**
 * 
 * @param {{[key: string]: Array<string>}} filterQueries - An object of arrays, where the key's are name of the property and the values are an array of property values
 * @returns {Array<{type: string, images: Array<{name: string, url: string}>}}
 */
export const getCharacters = async (filterQueries={}) => {
	const queryString = buildQuery(filterQueries);
	return GET('/princesses'+queryString);
}

export default {
	getSpellCards,
	getSpecialCards,
	getSymbols,
	getColors,
	getRules,
	getAds,
	getCharacters,
}