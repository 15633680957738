export default class CardSymbol {
	name = '';
	iconURL = '';
	selectedIconURL = '';

	/**
	 * @type {{[name: string]: CardSymbol}}
	 */
	static _cachedSymbols = {};

	/**
	 * 
	 * @param {{name: string, icon: {url: string}, selectedIcon: {url: string}}} symbol 
	 */
	constructor(symbol) {
		if(symbol === null || symbol === undefined) {
			return;
		}
		this.name = symbol.name;
		this.iconURL = symbol.icon.url
		this.selectedIconURL = symbol.selectedIcon.url;
		if (typeof CardSymbol._cachedSymbols[this.name] === 'undefined') {
			CardSymbol._cachedSymbols[this.name] = this;
		}
	}

	getIcon() {
		return this.selectedIconURL;
	}
}