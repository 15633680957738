import React from 'react';
import './styles.css';

const Footer = () => {
	return (
		<div className="app-footer">
			{/* <p>Website developed by <a href="https://christophergomez.info/" target="_blank">Christopher Gomez</a></p><br/> */}
			<p>Sparkle*Kitty and Sparkle*Kingdom designed by <a href="www.mannyvega.com" target="_blank">Manny Vega</a> Published by Breaking Games</p>
			<p>© Copyright 2020 <a href="www.breakinggames.com" target="_blank">Breaking Games</a> All Rights Reserved</p>
		</div>
	);
};

export default Footer;
