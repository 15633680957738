import React from 'react';
import './styles.css';
import { Card } from 'react-bootstrap';
// eslint-disable-next-line
import SpellCardModel from '../../../models/spell-card';

/**
 * 
 * @param {{ card: SpellCardModel, shapes: Array<JSX.Element>}} 
 */
const SpellCard = ({ card, shapes }) => {
	const [style, setStyle] = React.useState({letterSpacing: 0, fontSize: 4.75});
	const textWidth = (text, fontProp, letterSpacing) => {
		var tag = document.createElement('h1')
		tag.style.position = 'absolute';
		tag.style.left = '-99in';
		tag.style.whiteSpace = 'nowrap';
		tag.style.font = fontProp;
		tag.style.letterSpacing = letterSpacing;
		tag.innerHTML = text;
	
		document.body.appendChild(tag);
		var result = tag.scrollWidth;
		document.body.removeChild(tag);
		return result;
	}

	React.useEffect(() => {
		setStyle({letterSpacing: 0, fontSize: 4.75 });
	}, [card]);
	React.useEffect(() => {
		if (textWidth(card.word, "bolder "+style.fontSize+"em 'Yanone Kaffeesatz', sans-serif", style.letterSpacing +'px') > document.getElementById('spell-card-word').clientWidth) {
			const {letterSpacing, fontSize} = style;
			setStyle({letterSpacing: letterSpacing - 1.5, fontSize: fontSize});
		}
	}, [style, card.word]);
	const color = card.color;
	return (
		<Card className="back-face spell-card shadow-lg">
			<Card.Header className="header text-muted" style={{ backgroundColor: color.getValue() }}>
				<img
					src={card.symbol.iconURL}
					alt={card.symbol.name}
				/>
			</Card.Header>
			<Card.Body className="outer-body d-flex flex-column justify-content-center align-items-center">
				<div className="shape top">
					{shapes.map(el => {
						return el;
					})}
				</div>
				<Card.Body className="inner-body d-flex flex-row justify-content-center align-items-center" id="spell-card-word">
					<Card.Title as="h1" style={{letterSpacing: style.letterSpacing +"px", fontSize: style.fontSize+'em'}}>
						{card.word}
					</Card.Title>
				</Card.Body>
				<div className="shape bottom">
					{shapes.map(el => {
						return el;
					})}
				</div>
			</Card.Body>
			<Card.Footer className="footer text-muted" style={{ backgroundColor: color.getValue() }}>
				<img
					src={card.symbol.iconURL}
					alt={card.symbol.name}
				/>
			</Card.Footer>
		</Card>
	);
}

export default SpellCard;
