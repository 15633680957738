import React from "react";
import "./styles.css";
import { Container, Row, Col } from "react-bootstrap";
import showdown from "showdown";
import Characters from "../../components/characters";
import Footer from "../../components/footer";
import VideoPlayer from "../../components/video-player";
import ReactDOMServer from "react-dom/server";

const converter = new showdown.Converter();

	
/**
 *
 * @param {{rules: string}}
 */
const GameRules = ({ rules, characters, gameType }) => {
	const [state, setState] = React.useState({ loading: true });
	const video = ReactDOMServer.renderToString(
      <VideoPlayer youtubeId={"EXCC59gXsNI"} />
   );
   React.useEffect(() => {
      if (rules !== undefined && rules.length > 0) {
         let { loading } = state;
         const html = converter.makeHtml(rules);
         document.getElementById("mrkdn-container").innerHTML = html;
         document.getElementById("video-player").innerHTML = video;
         document.getElementById("howtoplay").onclick = () => {
            const element = document.getElementById("howtoplay");

            let pos = element.style.position;
            let top = element.style.top;
            element.style.position = "absolute";
            element.style.top = "-4em";
            element.scrollIntoView({ behavior: "smooth", block: "start" });
            element.style.top = top;
            element.style.position = pos;
         };

         loading = false;
         setState({ loading });
      }
   }, [rules]);

   return (
      <Container
         id="s-rules"
         fluid
         className="rules d-flex flex-row justify-content-center align-items-center"
      >
         <div id="rules" style={{ height: "100%", position: "relative" }}>
            <p style={state.loading ? {} : { display: "none" }}>Loading...</p>
            <Row>
               {!window.mobileCheck() && (
                  <Col
                     xs={1}
                     s={1}
                     md={1}
                     lg={3}
                     xl={3}
                     id="princess-container"
                  >
                     <Characters gameType={gameType} characters={characters} />
                  </Col>
               )}
               <Col>
                  <Row>
                     <div id="mrkdn-container"></div>
                  </Row>
                  <Row>
                     <Footer />
                  </Row>
               </Col>
               {!window.mobileCheck() && (
                  <Col xs={1} s={1} md={1} lg={3} xl={3}></Col>
               )}
            </Row>
         </div>
      </Container>
   );
};

export default GameRules;
