import React from "react";
import "./styles.css";
import GameManager from "../../services/game-manager";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
// eslint-disable-next-line
import SpecialCard from "../../models/special-card";
// eslint-disable-next-line
import SpellCard from "../../models/spell-card";
import SymbolPicker from "../../components/symbol-picker";
import Hand from "../hand";
import Card from "../../components/card";
import spellbook from "../../assets/images/SpellBook_Kingdom.png";
import keybook from "../../assets/images/SpellBook_KingdomNights.png";
import ActionButtons from "../action-buttons";
import BurstBG from "../../assets/images/Burst.svg";
import KnightsBG from "../../assets/images/BurstDark-01.svg";

class Game extends React.Component {
   /**
    *
    * @param {{loading: boolean, gameManager: GameManager, gameType: "STANDARD" | "NIGHTS"}} props
    */
   constructor(props) {
      super(props);

      /**
       * @type {{selectedSymbols: Array<string>, hand: Array<SpecialCard | SpellCard>, loading: boolean, gameState: string, stateButtonAnimation: {value: boolean, name: string}}}
       */
      this.state = {
         selectedSymbols: [],
         hand: [],
         loading: true,
         gameState: GameManager.GAME_STATES.PICKING,
         stateButtonAnimation: {
            value: false,
            name: "sheen",
         },
         buttonsDisabled: false,
      };

      /**
       * @type {GameManager}
       */
      this.gameManager = props.gameManager;

      /**
       * @type {Array<SpecialCard | SpellCard>}
       */
      this.deck = [];

      /**
       * @type {Array<SpecialCard | SpellCard>}
       */
      this.discards = [];

      /**
       * @type {Array<SpecialCard | SpellCard>}
       */
      this.holdingHand = []; // an array to hold current hand when hitting previous
      this.holdingDiscards = [];
   }

   componentDidMount = async () => {
      await this.gameManager.init();
      this.setState({ loading: false });
   };

   componentDidUpdate = (prevProps, prevState) => {
      if (
         this.state.loading === false &&
         prevProps.loading !== this.props.loading
      ) {
         this.setState({ loading: this.props.loading });
      }
      if (
         this.state.selectedSymbols.length ===
            GameManager.NUM_SYMBOLS_PER_DECK &&
         prevState.selectedSymbols.length !== this.state.selectedSymbols.length
      ) {
         this.setState({
            stateButtonAnimation: { value: true, name: "sheen" },
         });
      }
   };

   animateStateButton = (value) => {
      const { stateButtonAnimation } = this.state;
      stateButtonAnimation.value = value;
      stateButtonAnimation.name = "sheen";
      this.setState({ stateButtonAnimation });
   };

   selectSymbol = (symbol) => {
      const { selectedSymbols } = this.state;
      if (selectedSymbols.includes(symbol)) {
         const update = selectedSymbols.filter((_symbol) => _symbol !== symbol);
         this.setState({ selectedSymbols: update });
      } else {
         const update = [...selectedSymbols, symbol];
         this.setState({ selectedSymbols: update });
      }
   };

   initDeck = () => {
      this.setState({ loading: true }, () => {
         this.deck = this.gameManager.initDeck(this.state.selectedSymbols);
         this.setState(
            { loading: false, gameState: GameManager.GAME_STATES.PLAYING },
            () => {
               this.dealCards();
            }
         );
      });
   };

   dealCards = () => {
      let { hand, gameState } = this.state;
      if (this.deck.length === 0) {
         gameState = GameManager.GAME_STATES.PICKING;
         this.discards = [];
         this.deck = [];
         hand = [];
      } else {
         if (gameState === GameManager.GAME_STATES.PREVIOUS) {
            this.discards = [
               ...this.discards,
               ...hand,
               ...this.holdingDiscards.reverse(),
            ];
            hand = this.holdingHand;
            this.holdingDiscards = [];
            this.holdingHand = [];
         } else {
            this.discards = [...this.discards, ...hand];
            let wordsDifferent = false;
            do {
               wordsDifferent = true;
               hand = this.deck.splice(0, GameManager.HAND_SIZE);
               for (let i = 0; i < GameManager.HAND_SIZE - 1; i++) {
                  for (let j = i + 1; j < GameManager.HAND_SIZE; j++) {
                     if (hand[i].word === hand[j].word) {
                        wordsDifferent = false;
                        this.deck = [...this.deck, ...hand];
                        this.deck = this.gameManager.shuffle(this.deck);
                     }
                  }
               }
            } while (!wordsDifferent);
            this.deck = [...this.deck];
         }
         if (this.deck.length <= 2 && this.deck.length > 0) {
            gameState = GameManager.GAME_STATES.FINAL_ROUND;
         } else if (this.deck.length === 0) {
            gameState = GameManager.GAME_STATES.GAME_OVER;
         } else {
            gameState = GameManager.GAME_STATES.PLAYING;
         }
         console.log("Remaining cards: " + this.deck.length);
      }
      this.setState({ hand, gameState, buttonsDisabled: true }, () => {
         window.setTimeout(() => {
            this.setState({
               stateButtonAnimation: { value: true, name: "sheen" },
               buttonsDisabled: false,
            });
         }, 750);
      });
   };

   getPreviousCards = () => {
      let { hand, gameState } = this.state;
      if (this.discards.length <= 0) {
         return;
      }
      console.log(gameState);
      if (gameState === GameManager.GAME_STATES.PLAYING) {
         this.holdingHand = [...hand];
         hand = [];
         for (let i = 0; i < GameManager.HAND_SIZE; i++) {
            hand.push(this.discards.pop());
         }
         gameState = GameManager.GAME_STATES.PREVIOUS;
      } else {
         this.holdingDiscards = [...this.holdingDiscards, ...hand];
         hand = [];
         for (let i = 0; i < GameManager.HAND_SIZE; i++) {
            hand.push(this.discards.pop());
         }
      }
      hand = hand.reverse();
      this.setState({ hand, gameState, buttonsDisabled: true }, () => {
         window.setTimeout(() => {
            console.log(gameState);
            this.setState({
               stateButtonAnimation: { value: true, name: "sheen" },
               buttonsDisabled: false,
            });
         }, 750);
      });
   };

   newGame = () => {
      let { hand, gameState } = this.state;
      gameState = GameManager.GAME_STATES.PICKING;
      this.discards = [];
      this.deck = [];
      hand = [];
      this.setState({ hand, gameState });
   };

   render = () => {
      let stateButtonAction;
      switch (this.state.gameState) {
         case GameManager.GAME_STATES.PICKING:
            stateButtonAction = this.initDeck;
            break;
         case GameManager.GAME_STATES.FINAL_ROUND:
         case GameManager.GAME_STATES.PLAYING:
            stateButtonAction = this.dealCards;
            break;
         case GameManager.GAME_STATES.GAME_OVER:
            stateButtonAction = this.newGame;
            break;
         default:
            stateButtonAction = this.dealCards;
      }

      return this.state.loading && this.props.loading ? null : (
         <Container
            fluid
            className="game-container d-flex flex-row justify-content-center align-items-center"
            style={{
               backgroundImage: `url(${
                  this.props.gameType === "STANDARD" ? BurstBG : KnightsBG
               })`,
            }}
         >
            {this.state.loading ? (
               <div>Loading Deck...</div>
            ) : (
               <>
                  <div className="spellbook-container d-flex flex-row justify-content-center align-items-center">
                     <img
                        src={
                           this.props.gameType === "STANDARD"
                              ? spellbook
                              : keybook
                        }
                        alt="spellbook"
                     />
                     <div className="spellbook-content d-flex flex-row justify-content-center align-items-center">
                        {this.state.gameState ===
                           GameManager.GAME_STATES.PICKING && (
                           <SymbolPicker
                              NUM_SYMBOLS_PER_DECK={
                                 GameManager.NUM_SYMBOLS_PER_DECK
                              }
                              symbols={GameManager.symbols}
                              pickedSymbols={this.state.selectedSymbols}
                              onSymbolSelect={this.selectSymbol}
                              animateStateButton={this.animateStateButton}
                              gameType={this.props.gameType}
                           />
                        )}
                        {(this.state.gameState ===
                           GameManager.GAME_STATES.PLAYING ||
                           this.state.gameState ===
                              GameManager.GAME_STATES.PREVIOUS ||
                           this.state.gameState ===
                              GameManager.GAME_STATES.FINAL_ROUND ||
                           this.state.hand.length > 0) && (
                           <>
                              <Hand>
                                 {this.state.hand.map((card, index) => {
                                    return <Card card={card} key={index} />;
                                 })}
                              </Hand>
                           </>
                        )}
                     </div>
                     <ActionButtons
                        gameState={this.state.gameState}
                        action={stateButtonAction}
                        animation={this.state.stateButtonAnimation}
                        onAnimateEnd={(value) => {
                           this.animateStateButton(!value);
                        }}
                        onPrevious={this.getPreviousCards}
                        onNewDeck={this.newGame}
                        disabled={
                           this.state.gameState ===
                           GameManager.GAME_STATES.PICKING
                              ? this.state.selectedSymbols.length === 4
                                 ? false
                                 : true
                              : this.state.buttonsDisabled
                        }
                        gameType={this.props.gameType}
                     />
                  </div>
                  <svg
                     version="1.1"
                     id="castle-walls"
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink"
                     x="0px"
                     y="0px"
                     viewBox="0 0 1366 722"
                     xmlSpace="preserve"
                  >
                     <path
                        d="M1366,627.97h-33.07c-2.55,0-4.62,2.07-4.62,4.62v18.06c-13.36-1.66-27.26-3.27-41.66-4.83V619.7 
								c0-2.55-2.07-4.62-4.62-4.62h-47.3c-2.55,0-4.62,2.07-4.62,4.62v20.55c-15.12-1.36-30.66-2.67-46.62-3.92v-22.59
								c0-2.55-2.07-4.62-4.62-4.62h-47.3c-2.55,0-4.62,2.07-4.62,4.62v18.53c-19.35-1.28-39.2-2.47-59.52-3.57v-20.91
								c0-2.55-2.07-4.62-4.62-4.62h-47.3c-2.55,0-4.62,2.07-4.62,4.62v18.11c-19.12-0.85-38.58-1.64-58.36-2.33v-19.75
								c0-2.55-2.07-4.62-4.62-4.62h-47.31c-2.55,0-4.61,2.07-4.61,4.62v17.98c-19.21-0.53-38.67-0.98-58.37-1.36v-20.58
								c0-2.55-2.06-4.62-4.61-4.62h-47.31c-2.55,0-4.62,2.07-4.62,4.62v19.72c-19.27-0.23-38.73-0.39-58.36-0.47v-23.22
								c0-2.55-2.07-4.62-4.62-4.62h-47.3c-2.55,0-4.62,2.07-4.62,4.62v23.19c-19.63,0.06-39.09,0.19-58.36,0.4v-19.62
								c0-2.55-2.07-4.62-4.62-4.62h-47.31c-2.55,0-4.61,2.07-4.61,4.62v20.43c-19.69,0.35-39.15,0.77-58.37,1.28v-17.74
								c0-2.55-2.06-4.62-4.61-4.62h-47.31c-2.55,0-4.62,2.07-4.62,4.62v19.43c-19.77,0.67-39.23,1.43-58.36,2.25v-17.72
								c0-2.55-2.07-4.62-4.62-4.62h-47.3c-2.55,0-4.62,2.07-4.62,4.62v20.44c-15.71,0.82-25.16,1.71-40.32,2.63l-0.07-245.7
								c15.04-1.41,15.22-1.41,30.52-6.17c-20.13-13.02-22.45-21.2-37.61-51.69c-9.73-19.33-18.39-35.18-26.05-60.52v-8.45
								c0,0,7.16-5.77,12.26-5.95c7.52-0.27,9.84,5.8,18.61,5.99c0.48,0.01,10.61,0.08,16.37-7.75c3.43-4.67,4.22-10.66,3.59-10.99
								c-0.62-0.32-1.92,5.21-6.5,8.49c-5.21,3.75-12.81,3.26-18.16,0c-4.73-2.86-3.89-5.55-8.75-8.98c-6.13-4.34-14.05-4.75-16.83-2.84
								v-8.19h-4.66v40.37c-7.99,24.02-16.32,39.38-25.01,58.23c-14.13,30.06-19.27,38.36-35.37,51.98c12.78,4.62,15.46,4.23,28.33,5.71
								l0.06,251.21c-18.14,1.39-35.76,2.84-52.84,4.36l-0.08-407.47c17.89-1.69,18.1-1.69,36.32-7.34
								c-23.96-15.49-26.72-25.22-44.74-61.51c-11.59-22.99-21.88-41.83-30.99-71.99V81.6c0,0,8.52-6.86,14.58-7.07
								c8.95-0.33,11.72,6.89,22.14,7.12c0.57,0.02,12.63,0.1,19.47-9.21c4.09-5.57,5.01-12.69,4.27-13.07c-0.73-0.38-2.28,6.19-7.73,10.1
								c-6.2,4.45-15.23,3.88-21.61,0c-5.62-3.4-4.62-6.61-10.41-10.68c-7.29-5.16-16.71-5.65-20.03-3.37v-9.75h-5.55v48.02
								c-9.49,28.57-19.41,46.85-29.75,69.27c-16.8,35.75-22.93,45.63-42.07,61.83c15.21,5.5,18.38,5.03,33.71,6.81l0.06,416.78
								c-10.61,1.24-26.92,2.51-36.91,3.8v-19.57c0-2.55-2.07-4.62-4.62-4.62H-1V789h1367V627.97z M247.89,241.85
								c0.61,1.12,1.23,2.44,1.58,4.27c0.71,3.82-0.19,7.49-1.58,10.63V241.85z M119.49,62.01c0.71,1.33,1.47,2.9,1.87,5.08
								c0.84,4.53-0.23,8.91-1.87,12.65V62.01z"
                     />
                  </svg>
               </>
            )}
         </Container>
      );
   };
}

Game.propTypes = {
   gameManager: PropTypes.instanceOf(GameManager).isRequired,
   loading: PropTypes.bool.isRequired,
};

export default Game;
