import React from 'react';
import './styles.css';
import Fab from '@material-ui/core/Fab';
import { FaFacebookF } from 'react-icons/fa';

/**
 * 
 * @param {[{type: string, images: [{name: string, url: string}]}]} 
 */
const Ad = ({ ads }) => {
	const [state, setState] = React.useState({ towerAd: { currIndex: 0, ad: '' }, bannerAd: { currIndex: 0, ad: '' } });
	const positionTower = () => {
		const adEl = document.getElementById('tower-ad');
		const storeEl = document.getElementById('store-link');
		const width = adEl.offsetWidth < 100 ? 135 : adEl.offsetWidth / 2;
		const centerXPos = storeEl.offsetWidth / 2;
		const pos = (centerXPos - width);
		adEl.style.height = window.screen.height + 'px';
		adEl.style.right = pos + 'px';
	};

	const positionBanner = () => {
		const bannerAd = document.getElementById('banner');
		bannerAd.style.bottom = 0 + 'px';
		window.onscroll = (ev) => {
			bannerAd.style.opacity = 1;
			if ((window.innerHeight + window.scrollY) >= document.getElementById("app").offsetHeight) {
				bannerAd.style.opacity = 0;
			}
		};
	};
	const positionElems = () => {
		positionTower();
		positionBanner();
	}
	const setTowerAd = () => {
		let { towerAd } = state;
		for (const adObj of ads) {
			if (adObj.type === "tower") {
				towerAd.ad = adObj.images[towerAd.currIndex].url;
				towerAd.currIndex++;
				if (towerAd.currIndex === adObj.images.length) {
					towerAd.currIndex = 0;
				}
				break;
			}
		}
		setState({ ...state, towerAd });
	}
	const setBannerAd = () => {
		let { bannerAd } = state;
		for (const adObj of ads) {
			if (adObj.type === "banner") {
				bannerAd.ad = adObj.images[bannerAd.currIndex].url;
				bannerAd.currIndex++;
				if (bannerAd.currIndex === adObj.images.length) {
					bannerAd.currIndex = 0;
				}
				break;
			}
		}
		setState({ ...state, bannerAd });
	}
	const loop = () => {
		var rand = Math.round(Math.random() * (240000 - 500)) + 180000;
		window.setTimeout(() => {
			setTowerAd();
			setBannerAd();
			loop();
		}, rand);
	};
	React.useEffect(() => {
		if (ads.length > 0) {
			setTowerAd();
			setBannerAd();
			loop();
		}
	}, [ads]);

	React.useEffect(() => {
		window.removeEventListener('resize', positionElems, false);
		positionElems();
		window.addEventListener('resize', positionElems, false);
	}, [state.towerAd.ad]);
	return (
		<div className="prmtn-container">
			<div className="tower">
				<a href="http://sparkle-kitty.com/" target="_blank" rel="noopener noreferrer">
					<img src={state.towerAd.ad} alt="Ad" id="tower-ad" />
				</a>
				<a href="https://www.facebook.com/groups/sparklekitty/" target="_blank" rel="noopener noreferrer">
					<Fab color="primary" aria-label="facebook" id="social">
						<FaFacebookF size={20} />
					</Fab>
				</a>
			</div>
			<div className="banner">
				<a href="http://sparkle-kitty.com/" target="_blank" rel="noopener noreferrer">
					<img src={state.bannerAd.ad} alt="Ad" id="banner" />
				</a>
			</div>
		</div>
	);
}

export default Ad;
