import API from "./api";
import SpellCard from "../models/spell-card";
import SpecialCard from "../models/special-card";
import CardSymbol from "../models/card-symbol";
import CardColor from "../models/card-color";

export default class GameManager {
   static NUM_SYMBOLS_PER_DECK = 4;
   static HAND_SIZE = 2;
   static symbols = CardSymbol._cachedSymbols;
   static colors = CardColor._cachedColors;
   static GAME_STATES = {
      PICKING: "PICKING",
      PLAYING: "PLAYING",
      PREVIOUS: "PREVIOUS",
      FINAL_ROUND: "FINAL_ROUND",
      GAME_OVER: "GAME_OVER",
   };
   static GAME_TYPE = {
      STANDARD: "STANDARD",
      NIGHTS: "NIGHTS",
   };

   /**
    *
    * @param {"STANDARD" | "NIGHTS"} gameType
    */
   constructor(gameType) {
      /**
       * @type {Array<SpellCard | SpecialCard>}
       */
      this._allCards = [];
      this.gameType = gameType.toLowerCase();
      let status;
      switch (process.env.REACT_APP_ENV) {
         case "production":
            status = "PUBLISHED";
            break;
         case "staging":
            status = "STAGED";
            break;
         default:
            status = "STAGED";
      }
      this.status = [status, "PUBLISHED_STAGED"];
   }

   async init() {
      const arrs = await Promise.all([
         API.getSymbols(),
         API.getColors(),
         API.getSpellCards(),
         API.getSpecialCards(),
      ]);

      this._allCards = this._validateCards(arrs);
   }

   _validateCards(arr) {
      let i = 0;
      const spellCards = [],
         specialCards = [];

      for (const type of arr) {
         for (const symb of type) {
            if (this._validateAsset(symb))
               switch (i) {
                  case 0:
                     new CardSymbol(symb);
                     break;
                  case 1:
                     new CardColor(symb);
                     break;
                  case 2:
                     for (let i = 0; i < symb.quantity; i++) {
                        if (symb.symbol.name in CardSymbol._cachedSymbols) {
                           const card = new SpellCard(symb);
                           spellCards.push(card);
                        }
                     }
                     break;
                  case 3:
                     for (let i = 0; i < symb.quantity; i++) {
                        if (symb.color.name in CardColor._cachedColors) {
                           const card = new SpecialCard(symb);
                           specialCards.push(card);
                        }
                     }
               }
         }
         i++;
      }
      return [...spellCards, ...specialCards];
   }

   _validateAsset(asset) {
      if (
         asset[`${this.gameType}`] &&
         (asset.status === null || this.status.includes(asset.status))
      ) {
         return true;
      }
      return false;
   }

   async getRules() {
      const response = await API.getRules();
      for (const ruleset of response) {
         if (this._validateAsset(ruleset)) {
            return ruleset.rules;
         }
      }
   }

   async getAds() {
      const response = await API.getAds();
      const ads = [];
      for (const ad of response) {
         if (this._validateAsset(ad)) {
            ads.push(ad);
         }
      }
      return ads;
   }

   async getCharacters() {
      const response = await API.getCharacters();
      const characters = [];
      for (const characterSet of response) {
         if (this._validateAsset(characterSet)) {
            characters.push(characterSet);
         }
      }
      return characters;
   }

   /**
    *
    * @param {Array<string | CardSymbol>} symbols
    * @returns {Array<SpecialCard | SpellCard>}
    */
   initDeck(symbols) {
      if (!Array.isArray(symbols)) {
         throw new Error("Parameter is not an Array");
      }

      if (symbols.length !== GameManager.NUM_SYMBOLS_PER_DECK) {
         throw new Error("Invalid Array Length");
      }

      if (this._allCards.length === 0) {
         throw new Error(
            "GameManager not initiated yet. Download the entire deck first"
         );
      }

      const deck = [];
      for (const symbol of symbols) {
         deck.push(...this.getCardsOfSymbol(symbol));
      }
      deck.push(...this.getSpecialCards());
      return this.shuffle(deck);
   }

   /**
    * @returns {Array<SpecialCard>}
    */
   getSpecialCards() {
      return this._allCards.filter((card) => {
         return !(card instanceof SpellCard) && card instanceof SpecialCard;
      });
   }

   /**
    *
    * @param {string | CardSymbol} symbol
    * @returns {Array<SpecialCard | SpellCard>}
    */
   getCardsOfSymbol(symbol) {
      let searchKey;
      if (symbol instanceof CardSymbol) {
         searchKey = symbol.name;
      } else {
         searchKey = symbol;
      }

      return this._allCards.filter((card) => {
         return card instanceof SpellCard && card.getSymbolName() === searchKey;
      });
   }

   /**
    *
    * @param {string | CardColor} color
    * @returns {Array<SpecialCard | SpellCard>}
    */
   getCardsOfColor(color) {
      let searchKey;
      if (color instanceof CardColor) {
         searchKey = color.getName();
      } else {
         searchKey = color;
      }

      return this._allCards.filter((card) => {
         return (
            card instanceof SpecialCard && card.getColorName() === searchKey
         );
      });
   }

   /**
    *
    * @param {Array<SpecialCard | SpellCard>} cards
    * @returns {Array<SpecialCard | SpellCard>}
    */
   shuffle(cards) {
      for (let i = cards.length - 1; i > 0; i--) {
         const j = Math.floor(Math.random() * (i + 1));
         [cards[i], cards[j]] = [cards[j], cards[i]];
      }
      return cards;
   }
}
