class Color {
	name = '';
	value = '';

	constructor(name, value) {
		this.name = name;
		this.value = value;
	}
};

export default class CardColor {
	name = '';
	colors = [new Color()];
	
	/**
	 * @type {{[name: string]: CardColor}}
	 */
	static _cachedColors = {};

	/**
	 * 
	 * @param {{name: string, color: [{name: string, value: string}]}} color - An object literal that describes the color
	 */
	constructor (color) {
		if(color === null || color === undefined) {
			return;
		}

		if(typeof color !== 'object' || color === null) {
			throw new Error('Color must be an object literal!');
		}

		this.name = color.name;
		this.colors = [];
		for(const value of Object.values(color.color)) {
			this.colors.push(new Color(value.name, value.value));
		}
		if (typeof CardColor._cachedColors[this.name] === 'undefined') {
			CardColor._cachedColors[this.name] = this;
		}
	}

	getValue() {
		if(this.colors.length > 1) {
			let vals = [];
			for(const val of this.colors) {
				vals.push(val.value);
			}
			return vals;
		}
		return this.colors[0].value;
	}

	getName() {
		return this.name;
	}
}