import React from 'react';
import '../styles.css';

/**
 * 
 * @param {[]} 
 */
const FeaturedPrincesses = ({ characters, setCurrFeatured }) => {
	const [state, setState] = React.useState({ featuredPrincesses: {}, currFeaturedIndex: 0 });
	React.useEffect(() => {
		if (characters.length > 0) {
			let { featuredPrincesses, currFeaturedIndex } = state;
			featuredPrincesses = characters.filter((princessObj) => {
				return princessObj.type === 'featured';
			});
			featuredPrincesses = featuredPrincesses[0].images.map((princessImg) => {
				return { name: princessImg.name.replace(/[^A-Za-z]+/g, ''), url: princessImg.url }
			});
			currFeaturedIndex = Math.floor(Math.random() * featuredPrincesses.length);
			setCurrFeatured(featuredPrincesses[currFeaturedIndex].name);
			setState({ currFeaturedIndex, featuredPrincesses })
			
		}
	}, [characters]);
	return (
		state.featuredPrincesses.length > 0 && <img src={state.featuredPrincesses[state.currFeaturedIndex].url} alt="Featured Princess" id="featured" />
	);
}

export default FeaturedPrincesses;
