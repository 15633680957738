import React from 'react';
import './styles.css';
import { Container, Row, Col } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
// import useDeepCompareEffect from 'use-deep-compare-effect'

const Hand = ({ children }) => {
	const [animate, setAnimate] = React.useState(true);
	const firstUpdate = React.useRef(true);
	const cards = React.Children.toArray(children);
	let card;
	if(cards[0])
		card = cards[0].props.card;

	React.useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		setAnimate(false);
	}, [card]);

	React.useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		setAnimate(true);
	}, [animate])

	return (
		<Container fluid className="hand d-flex flex-row justify-content-center align-items-center">
			<Row>
				{cards.map((card, index) => {
					return (
						<Col key={index} className={index === 0 ? 'first' : ''}>
							<CSSTransition
								in={animate}
								unmountOnExit
								timeout={700}
								classNames="alert"
							>
								{card}
							</CSSTransition>
						</Col>

					);
				})}
			</Row>
		</Container>
	);
}


export default Hand;
