import React from 'react';
import './styles.css';
import { Button } from 'react-bootstrap';
import GameManager from '../../services/game-manager';

/**
 * 
 * @param {{gameState: string, onSelect: () => void, animate: {value: boolean, name: string}, disabled: boolean, onAnimateEnd: (value: boolean) => void}} 
 */
const StateButton = ({ gameState, onSelect, animate, disabled, onAnimateEnd, gameType }) => {
	const [hovered, setHovered] = React.useState(false);
	const onAnimEnd = () => {
		onAnimateEnd(true);
	}
	const onHover = (val) => {
		setHovered(val);
	}
	const calloutClass = animate.value ? ' ' + animate.name : '';
	const gameClass = gameType === "STANDARD" ? " standard" : " nights";
	const className = 'action-button' + calloutClass + gameClass;
	return (
		<Button
			size="lg"
			style={gameState === GameManager.GAME_STATES.FINAL_ROUND && hovered ? {width: '10rem'} : {}}
			className={className}
			onAnimationEnd={onAnimEnd}
			disabled={disabled}
			onClick={onSelect}
			onMouseOver={() => {
				onHover(true);
			}}
			onMouseOut={() => {
				onHover(false);
			}}
		>
			{gameState === GameManager.GAME_STATES.PICKING && 'START'}
			{gameState === GameManager.GAME_STATES.PLAYING && 'CONTINUE'}
			{gameState === GameManager.GAME_STATES.PREVIOUS && 'RESUME'}
			{gameState === GameManager.GAME_STATES.FINAL_ROUND && (hovered ? 'FINAL TURN' : 'CONTINUE')}
			{gameState === GameManager.GAME_STATES.GAME_OVER && (hovered ? 'NEW GAME' : 'FINAL TURN')}
		</Button>
	);
}

export default StateButton;
