import React from "react";
import "./styles.css";
import logo from "../../assets/images/logo.svg";
import logoNights from "../../assets/images/logo_nights.svg";
import { Navbar, Nav } from "react-bootstrap";
// eslint-disable-next-line
import CardColor from "../../models/card-color";

const scrollToRef = (position) => {
   window.scrollTo(0, position);
};

/**
 *
 * @param {{colors: {[name: string]: CardColor}, gameType: "STANDARD" | "NIGHTS"}}
 */
const Header = ({ colors, gameType }) => {
   const [hovered, setHovered] = React.useState([false, false, false, false]);
   return (
      <Navbar collapseOnSelect expand="md" variant="dark" fixed id="nav-bar">
         <Navbar.Brand>
            <img
               src={gameType === "NIGHTS" ? logoNights : logo}
               className="d-inline-block align-bottom"
               alt="Sparkle*Kitty logo"
            />
         </Navbar.Brand>
         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
         <Navbar.Collapse
            className="align-bottom justify-content-end"
            id="responsive-navbar-nav"
         >
            <Nav
               style={{ width: "100%", margin: "auto" }}
               fill
               className="d-flex align-bottom justify-content-end"
            >
               <Nav.Item
                  onMouseEnter={() => setHovered([true, false, false, false])}
                  onMouseLeave={() => setHovered([false, false, false, false])}
                  onClick={() => {
                     document.querySelector(".game-container").scrollIntoView({
                        behavior: "smooth",
                     });
                  }}
               >
                  <Nav.Link
                     style={
                        Object.keys(colors).length > 0 && hovered[0]
                           ? { color: colors["Red"].getValue() }
                           : { color: "white" }
                     }
                  >
                     Play
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item
                  onMouseEnter={() => setHovered([false, true, false, false])}
                  onMouseLeave={() => setHovered([false, false, false, false])}
                  onClick={() => {
                     const element = document.getElementById("howtoplay");

                     let pos = element.style.position;
                     let top = element.style.top;
                     element.style.position = "absolute";
                     element.style.top = "-4em";
                     element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                     });
                     element.style.top = top;
                     element.style.position = pos;

                     // window.scrollTo({ top: y, behavior: 'smooth' });
                     // element.scrollIntoView({
                     // 	inline: "start",
                     // 	behavior: 'smooth'
                     // });
                  }}
               >
                  <Nav.Link
                     style={
                        Object.keys(colors).length > 0 && hovered[1]
                           ? { color: colors["Blue"].getValue() }
                           : { color: "white" }
                     }
                  >
                     Rules
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item
                  onMouseEnter={() => setHovered([false, false, true, false])}
                  onMouseLeave={() => setHovered([false, false, false, false])}
                  onClick={() => {
                     const element = document.getElementById("downloads");

                     // let pos = element.style.position;
                     // let top = element.style.top;
                     // element.style.position = 'absolute';
                     // element.style.top = '2em';
                     element.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                     });
                     // element.style.top = top;
                     // element.style.position = pos;
                  }}
               >
                  <Nav.Link
                     style={
                        Object.keys(colors).length > 0 && hovered[2]
                           ? { color: colors["Yellow"].getValue() }
                           : { color: "white" }
                     }
                  >
                     Download
                  </Nav.Link>
               </Nav.Item>
               <Nav.Item
                  id="store-link"
                  onMouseEnter={() => setHovered([false, false, false, true])}
                  onMouseLeave={() => setHovered([false, false, false, false])}
               >
                  <Nav.Link
                     href="http://sparkle-kitty.com/"
                     style={
                        Object.keys(colors).length > 0 && hovered[3]
                           ? { color: colors["Green"].getValue() }
                           : { color: "white" }
                     }
                     target="_blank"
                  >
                     Purchase
                  </Nav.Link>
               </Nav.Item>
            </Nav>
         </Navbar.Collapse>
      </Navbar>
   );
};

export default Header;
