import React from 'react';
import './styles.css';

/**
 * 
 * @param {[]} 
 */
const WindowedPrincesses = ({ characters, currFeatured, gameType }) => {
	const [state, setState] = React.useState({ windowedPrincesseses: {}, windowedImgs: [] });
	React.useEffect(() => {
		if (characters.length > 0) {
			let { windowedPrincesseses, windowedImgs } = state;
			windowedPrincesseses = characters.filter((princessObj) => {
				return princessObj.type === 'windowed'
			});
			windowedPrincesseses = windowedPrincesseses[0].images.map((princessImg) => {
				return { name: princessImg.name.replace(/[^A-Za-z]+/g, ''), url: princessImg.url, images: [] }
			});
			windowedImgs = (() => {
				if (!Array.isArray(windowedPrincesseses)) {
					windowedPrincesseses = characters.filter((princessObj) => {
						return princessObj.type === 'windowed'
					});
					windowedPrincesseses = windowedPrincesseses[0].images.map((princessImg) => {
						return { name: princessImg.name.replace(/[^A-Za-z]+/g, ''), url: princessImg.url, images: [] }
					});
				}
				for (let i = windowedPrincesseses.length - 1; i > 0; i--) {
					const j = Math.floor(Math.random() * (i + 1));
					[windowedPrincesseses[i], windowedPrincesseses[j]] = [windowedPrincesseses[j], windowedPrincesseses[i]];
				}
				let counter = 0;
				let imgs = [];
				
				windowedPrincesseses.forEach((princess, index) => {
					if (princess.name === currFeatured) {
						return;
					}
					let height = 100;
					if (counter > 0) {
						height += (500 * counter);
					}
					let left = 0;
					if (counter % 2) {
						left = 5;
					}
					imgs.push(<img src={princess.url} alt={princess.name + " Window"} key={index} style={{ top: (height) + "px", left: left + "em" }} />);
					counter++;
				});
				const repeatImgs = [];
				windowedPrincesseses.forEach((princess, index) => {
					if (princess.name === currFeatured) {
						return;
					}
					let height = 100;
					if (counter > 0) {
						height += (500 * counter);
					}
					let left = 0;
					if (counter % 2) {
						left = 5;
					}
					repeatImgs.push(<img src={princess.url} alt={princess.name + " Window"} key={index} style={{ top: (height) + "px", left: left + "em" }} />);
					counter++;
				});
				imgs = [...imgs, ...repeatImgs];
				return imgs;
			})();
			setState({loading: false, windowedPrincesseses, windowedImgs });
		}
	}, [characters]);
	React.useEffect(() => {
		let { windowedImgs, windowedPrincesseses } = state;
		windowedImgs = (() => {
			if (!Array.isArray(windowedPrincesseses)) {
				windowedPrincesseses = characters.filter((princessObj) => {
					return princessObj.type === 'windowed'
				});
				windowedPrincesseses = windowedPrincesseses[0].images.map((princessImg) => {
					return { name: princessImg.name.replace(/[^A-Za-z]+/g, ''), url: princessImg.url, images: [] }
				});
			}
			for (let i = windowedPrincesseses.length - 1; i > 0; i--) {
				const j = Math.floor(Math.random() * (i + 1));
				[windowedPrincesseses[i], windowedPrincesseses[j]] = [windowedPrincesseses[j], windowedPrincesseses[i]];
			}
			let counter = 0;
			let imgs = [];
			
			windowedPrincesseses.forEach((princess, index) => {
				if (princess.name === currFeatured) {
					return;
				}
				let height = 100;
				if (counter > 0) {
					height += (500 * counter);
				}
				let left = 0;
				if (counter % 2) {
					left = 5;
				}
				imgs.push(<img src={princess.url} alt={princess.name + " Window"} key={index} style={{ top: (height) + "px", left: left + "em" }} />);
				counter++;
			});
			const repeatImgs = [];
			windowedPrincesseses.forEach((princess, index) => {
				if (princess.name === currFeatured) {
					return;
				}
				let height = 100;
				if (counter > 0) {
					height += (500 * counter);
				}
				let left = 0;
				if (counter % 2) {
					left = 5;
				}
				repeatImgs.push(<img src={princess.url} alt={princess.name + " Window"} key={index} style={{ top: (height) + "px", left: left + "em" }} />);
				counter++;
			});
			imgs = [...imgs, ...repeatImgs];
			return imgs;
		})();
		setState({ ...state, windowedImgs });
	}, [currFeatured]);
	return (
		<div className="windows standard" id="windows">
			 {state.windowedImgs}
		</div>
	);
}

export default WindowedPrincesses;
