import React from 'react';
import './styles.css';
import { Card } from 'react-bootstrap';
// eslint-disable-next-line
import SpecialCardModel from '../../../models/special-card';

/**
 * 
 * @param {{card: SpecialCardModel, shapes: Array<JSX.Element>}}  
 */
const SpecialCard = ({ card, shapes }) => {
	const [style, setStyle] = React.useState({letterSpacing: 0, fontSize: 4.75});
	const textWidth = (text, fontProp, letterSpacing) => {
		var tag = document.createElement('h1')
		tag.style.position = 'absolute';
		tag.style.left = '-99in';
		tag.style.whiteSpace = 'nowrap';
		tag.style.font = fontProp;
		tag.style.letterSpacing = letterSpacing;
		tag.innerHTML = text;
	
		document.body.appendChild(tag);
		var result = tag.scrollWidth;
		document.body.removeChild(tag);
		return result;
	}

	React.useEffect(() => {
		setStyle({letterSpacing: 0, fontSize: 4.75 });
	}, [card]);
	React.useEffect(() => {
		if (textWidth(card.word, "bolder "+style.fontSize+"em 'Yanone Kaffeesatz', sans-serif", style.letterSpacing +'px') > document.getElementById('special-card-word').clientWidth) {
			const {letterSpacing, fontSize} = style;
			setStyle({letterSpacing: letterSpacing - 1.5, fontSize: fontSize});
		}
	}, [style, card.word]);

	if (Array.isArray(card.color.getValue())) {
		// Rainbow Card
		const colors = card.color.getValue();
		colors.reverse();
		let background = 'linear-gradient(0deg, ';
		let i = 0;
		let currPercent;
		let prevPercent = null;
		for (const color of colors) {
			prevPercent ? background += color + ' ' + prevPercent + '%,' : background += color + ',';
			currPercent = ((i + 1) / colors.length) * 100;
			if(i === Math.floor(colors.length / 2)) {
				currPercent += 3;
			} else if(i === Math.floor(colors.length / 2) - 1) {
				currPercent -= 3;
			}
			prevPercent = currPercent;
			background += color + ' ' + currPercent + '%,';
			i++
		}
		background = background.substring(0, background.length - 1); // remove the last comma
		background += ')';
		return (
			<Card className="back-face special-card shadow-lg">
				<Card.Body className="d-flex flex-row justify-content-center align-items-center" style={{ backgroundImage: background, color: 'white', borderRadius: '1em' }} id="special-card-word">
					<Card.Title as="h1" style={{letterSpacing: style.letterSpacing +"px", fontSize: style.fontSize+'em'}}>
						{card.word}
					</Card.Title>
				</Card.Body>
			</Card>
		);
	} else {
		if (card.color.name === 'Black') {
			return (
				<Card className="back-face special-card shadow-lg">
					<Card.Body className="d-flex flex-row justify-content-center align-items-center" style={{ backgroundColor: card.getColorVal(), color: 'white', borderRadius: '1em', border: '6px solid white' }} id="special-card-word">
						<Card.Title as="h1" style={{letterSpacing: style.letterSpacing +"px", fontSize: style.fontSize+'em'}}>
							{card.word}
						</Card.Title>
					</Card.Body>
				</Card>
			);
		} else {
			return (
				<Card className="back-face special-card shadow-lg">
					<Card.Header className="header text-muted">
						<div className="symbols">
							{shapes.map(el => {
								return el;
							})}
						</div>
					</Card.Header>
					<Card.Body className="d-flex flex-row justify-content-center align-items-center" style={{ backgroundColor: card.getColorVal(), color: 'white' }} id="special-card-word">
						<Card.Title as="h1" style={{letterSpacing: style.letterSpacing +"px", fontSize: style.fontSize+'em'}}>
							{card.word}
						</Card.Title>
					</Card.Body>
					<Card.Footer className="footer text-muted">
						<div className="symbols">
							{shapes.map(el => {
								return el;
							})}
						</div>
					</Card.Footer>
				</Card>
			);
		}
	}
}

export default SpecialCard;
