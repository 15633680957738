import React from "react";
import "./styles.css";
import numToWord from "num-words";
// eslint-disable-next-line
import CardSymbol from "../../models/card-symbol";
import { Card, Container, Row, Col } from "react-bootstrap";
import { IconButton } from "@material-ui/core";

/**
 *
 * @param {{NUM_SYMBOLS_PER_DECK: number, pickedSymbols: Array<string>, symbols: {[name: string]: CardSymbol}, selectSymbol(symbol: string) => void, }}
 */
const SymbolPicker = ({
   NUM_SYMBOLS_PER_DECK,
   pickedSymbols,
   symbols,
   onSymbolSelect,
   gameType,
}) => {
   const _symbolOrder = ["Star", "Heart", "Horseshoe", "Crown"];
   const symbolOrder = [];
   for (const symb of _symbolOrder) {
      if (Object.keys(symbols).includes(symb)) {
         symbolOrder.push(symb);
      }
   }
   let symbolKeys = [
      ...symbolOrder,
      ...Object.keys(symbols).filter((symbolKey) => {
         return !symbolOrder.includes(symbolKey);
      }),
   ];
   // symbolKeys = symbolKeys.reduce(function (res, current, index, array) {
   // 	return res.concat([current, current]);
   // }, []);

   const gameClass = gameType === "STANDARD" ? "standard" : "nights";
   const className = "symbol-picker shadow-lg p-2 rounded " + gameClass;
   return (
      <Card text="light" className={className}>
         {" "}
         {/*style={{ width: '18rem' }}>*/}
         <Card.Body>
            {pickedSymbols.length < NUM_SYMBOLS_PER_DECK ? (
               <Card.Title as="h2">
                  SELECT{" "}
                  {numToWord(
                     NUM_SYMBOLS_PER_DECK - pickedSymbols.length
                  ).toUpperCase()}
                  {pickedSymbols.length > 0 ? " MORE" : ""}
                  {pickedSymbols.length < NUM_SYMBOLS_PER_DECK - 1
                     ? " SYMBOLS"
                     : " SYMBOL"}{" "}
                  {pickedSymbols.length > 0 ? "" : " TO ADD TO YOUR "}
                  {gameType === "STANDARD" ? "SPELLBOOK" : "KEYHOLE"}
               </Card.Title>
            ) : (
               <Card.Title as="h2">START THE GAME!</Card.Title>
            )}
            <Container className="p-2 rounded">
               <Row>
                  {symbolKeys.map((symbolName, index) => {
                     const img = pickedSymbols.includes(symbolName)
                        ? symbols[symbolName].selectedIconURL
                        : symbols[symbolName].iconURL;
                     const selected = pickedSymbols.includes(symbolName)
                        ? " selected"
                        : "";
                     const className = "symbol-button" + selected;
                     return (
                        <Col
                           key={index}
                           xs={symbolKeys.length <= 6 ? 6 : 4}
                           sm={symbolKeys.length <= 6 ? 6 : 4}
                           md={symbolKeys.length <= 6 ? 4 : 2}
                           lg={symbolKeys.length <= 6 ? 4 : 2}
                        >
                           <IconButton
                              color="primary"
                              className={className}
                              onClick={() => {
                                 onSymbolSelect(symbolName);
                              }}
                              disabled={
                                 pickedSymbols.length === 4 &&
                                 !pickedSymbols.includes(symbolName)
                              }
                              style={
                                 symbolKeys.length <= 6 ? {} : { margin: 0 }
                              }
                           >
                              <img src={img} alt={symbolName} />
                           </IconButton>
                        </Col>
                     );
                  })}
               </Row>
            </Container>
         </Card.Body>
      </Card>
   );
};

export default SymbolPicker;
