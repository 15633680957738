import React from 'react';
import PropTypes from 'prop-types';
import SpecialCardModel from '../../models/special-card';
import SpellCardModel from '../../models/spell-card';
import SpecialCard from './special-card';
import SpellCard from './spell-card';
import { FaCaretDown, FaSquare, FaCircle } from 'react-icons/fa';
import { GoDash } from 'react-icons/go';
import { MdClose } from 'react-icons/md';
import './styles.css';

/**
 * 
 * @param { {card: SpecialCardModel | SpellCardModel} } props
 */
const Card = ({ card }) => {
	const shapes = ((color) => {
		const arr = [];
		for (let i = 0; i < 10; i++) {
			switch(color.name) {
				case 'Blue':
					arr.push(<FaSquare key={i} size={12} id="square" color={color.getValue()}/>)
					break;
				case 'Purple':
					arr.push(<GoDash key={i} size={30} color={color.getValue()}/>)
					break;
				case 'Red':
					arr.push(<MdClose key={i} size={20} id="cross" color={color.getValue()}/>)
					break;
				case 'Green':
					arr.push(<FaCaretDown key={i} size={30} color={color.getValue()}/>)
					break;
				case 'Yellow':
					arr.push(<FaCircle key={i} size={16} id="circle" color={color.getValue()}/>)
					break;
				default:
					return;
			}
		}
		return arr;
	})(card.color);
	const color = ((color) => {
		let col;
		switch(color.name) {
			case 'Blue':
			case 'Purple':
			case 'Red':
			case 'Green':
			case 'Yellow':
			case 'Black':
				col = color.getValue();
				break;
			case 'Rainbow':
				col = color.getValue()[0];
				break;
			default:
				return 'white';
		}
		return col;
	})(card.color);
	let cardEl;
	if (card instanceof SpecialCardModel && !(card instanceof SpellCardModel)) {
		cardEl = <SpecialCard card={card} shapes={shapes} />
	} else if(card instanceof SpellCardModel) {
		cardEl = <SpellCard card={card} shapes={shapes} />
	} else {
		cardEl = null;
	}
	return (
		<div className="game-card">
			{cardEl}
			<div className="front-face" style={{backgroundColor: color}}>
			</div>
		</div>
	)
};

Card.propTypes = {
	card: PropTypes.oneOfType([
		PropTypes.instanceOf(SpecialCardModel),
		PropTypes.instanceOf(SpellCardModel)
	])
};

export default Card;